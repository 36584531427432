<template>
  <div class="jiedu-choose">
    <div :class="h270">
      <div :class="['radio', {'active': value === '' + item.id}]" v-for="item in list.slice(0,3)" :key="item.id" @click="clickRadio(item.id)" style="margin-bottom:10px">
        <div class="flex">
          <div
            :style="item.declareDate == '已截止'?'color:gray':'color:red'"
            style="margin-right: 30px;"
          >
            {{ item.declareDate === '已截止' ? '【 已截止 】' : item.declareDate == 0? '【 今天截止 】' : '【 剩 ' +item.declareDate+ ' 天 】' }}
          </div>
          <div style="flex:1;margin-left:10px">{{ item.subtitle }} </div>
        </div>
      </div>
    </div>
     <div v-show="!show">
      <div :class="['radio', {'active': value === '' + item.id}]" v-for="item in list.slice(3,list.length+1)" :key="item.id" @click="clickRadio(item.id)" style="margin-bottom:10px">
        <div class="flex">
          <div
            :style="item.declareDate == '已截止'?'color:gray':'color:red'"
            style="margin-right: 30px;"
          >
            {{ item.declareDate === '已截止' ? '【 已截止 】' : item.declareDate == 0? '【 今天截止 】' : '【 剩 ' +item.declareDate+ ' 天 】' }}
          </div>
          <div style="flex:1;margin-left:10px">{{ item.subtitle }}</div>
        </div>
      </div>
    </div>
    <div class="expandAll" @click="show = !show" v-show="list.length>3">{{show?'展开':'收起'}}<i :class="show?'reverse_triangle':'just_triangle'"></i></div>
  </div>
</template>

<script>
export default {
  props: {
    value: {
      type: String
    },
    list: {
      type: Array,
      default: () => []
    }
  },
  data () {
    return {
      length: '',
      chooseLength: 0,
      show: true,
      expand: '展开'
    };
  },
  methods: {
    clickRadio (id) {
      this.$emit('input', '' + id);
      // this.$emit("handleChoose",id)
    }
  },
  watch: {
    list: {
      handler (newV) {
        this.length = newV.length;
        this.list.forEach((item, index) => {
          if ('' + item.id === this.value) this.chooseLength = index;
        });
      },
      deep: true
    }
  },
  computed: {
    h270 () {
      if (this.length > 5 && this.chooseLength < 5) {
        return 'h270';
      }
      return '';
    }
  }
};
</script>

<style lang="less" scoped>
.jiedu-choose {
  .h270 {
    height: 175px;
    overflow: hidden;
  }
  .radio {
    display: flex;
    height:49px;
    border: 2px solid #e5e5e5;
    border-radius: 5px;
    padding: 16px 20px;
    box-sizing: border-box;
    cursor: pointer;
    & + div {
      // margin-top: 10px;
    }
    &.active {
      color: #156ED0;
      background-color:rgb(242, 247, 252) ;
      // border: 2px solid #156ED0;
    }
    .left {
      width: 800px;
      div:nth-child(1) {
        white-space: nowrap;
        overflow: hidden;
        text-overflow: ellipsis;
      }
      div:nth-child(2) {
        height: 15px;
        font-size: 14px;
        margin-top: 12px;
        color: #b8b8b8;
        white-space: nowrap;
        overflow: hidden;
        text-overflow: ellipsis;
      }
    }
    & > div:nth-child(2) {
      flex: 1;
      align-self: center;
      text-align: end;
      font-size: 16px;
      color: #b8b8b8;
      &.red {
        color: red;
      }
    }
  }
  .expandAll {
    background-color: #f5f5f5;
    font-size: 14px;
    height: 40px;
    line-height: 40px;
    text-align: center;
    cursor: pointer;
  }
}
.r_color {
  color: red;
}

 .just_triangle {
     position: relative;
      bottom: 12px;
      left: 5px;
      border-bottom: 6px solid;
      border-right: 6px solid transparent;
      border-left: 6px solid transparent;
    }
.reverse_triangle{
      position: relative;
      top: 12px;
      left: 5px;
      border-top: 6px solid;
      border-right: 6px solid transparent;
      border-left: 6px solid transparent;
}
.sysj{
  position: relative;
  left: -950px;
}
.titlename{
  font-size: 16px;
  position: relative;
  left: 120px;
}
.zc1{
     position: relative;
       left: 755px;
    width: 300px;
    top: -30px;
}
.zc2{
float: right;
}
.jieduList{
  position: relative;
}
.timer{
  position: absolute;
  left: 0px;
}
.content{
  text-indent: 140px;
}
</style>
